import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Relift from "../../../images/media/products/relift/relift.svg"
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"

const Product = () => (
  <Layout lang={"EN"}>
    <Seo
      title="Ultranaprawcze seurm do twarzy na noc - Linia Relift"
      lang={"EN"}
    />
    <ProductHero>
      {/* Info */}
      <div data-column>
        <ProductInfo color="magenta">
          <H1>Ultra repair night-time face serum</H1>
          <p>Redefined facial contours</p>
          <ProductPackshot mobile>
            <StaticImage
              src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
              loading="eager"
              width={580}
              quality={95}
              placeholder="none"
              formats={["auto", "webp", "avif"]}
              alt="Ultra repair night-time face serum"
            />
            <ProductPackshotBubbles />
          </ProductPackshot>

          <ul>
            <li>Stimulates natural skin repair process mechanisms.</li>
            <li>Revitalises skin and locks in hydration. </li>
            <li>
              Oxygenates and energises skin cells to replenish and allow nice
              new plump skin cells to form.
            </li>
            <li>
              Enhances microbiome and rebuilds natural skin protective barrier.
            </li>
          </ul>
          <Container>
            <ButtonColor
              as="a"
              target="_blank"
              href="https://www.hebe.pl/oxygenetic-ultranaprawcze-serum-do-twarzy-na-noc-50-ml-000000000000407514.html"
              color="magenta"
            >
              Buy in Hebe
            </ButtonColor>
            <ButtonColor
              as="a"
              target="_blank"
              href="https://www.zikodermo.pl/oxygenetic-relift-ultranaprawcze-serum-do-twarzy-na-noc-50-ml.html"
              color="magenta"
            >
              Buy in Ziko Dermo
            </ButtonColor>
            <ButtonColor
              as="a"
              target="_blank"
              href="https://gemini.pl/oxygenetic-relift-vitamin-a-ultranaprawcze-serum-do-twarzy-na-noc-50-ml-0107791"
              color="magenta"
            >
              Buy in Gemini
            </ButtonColor>
            <ButtonColor
              as="a"
              target="_blank"
              href="https://www.doz.pl/apteka/p176924-Oxygenetic_Relift_Vitamin_A_ultranaprawcze_serum_do_twarzy_na_noc_50_ml"
              color="magenta"
            >
              Buy in DOZ
            </ButtonColor>
          </Container>
        </ProductInfo>
      </div>

      {/* Packshot */}
      <div data-column>
        <ProductPackshot>
          <StaticImage
            src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
            loading="eager"
            width={580}
            quality={95}
            placeholder="none"
            formats={["auto", "webp", "avif"]}
            alt="ultranaprawcze-serum"
          />
          <ProductPackshotBubbles />
        </ProductPackshot>
        <More url="#opis-produktu" color="magenta" text="more" />
      </div>

      {/* Category Products */}
      <div data-column>
        <ProductSidebar lang={"EN"}>
          <Link to="/en/products/smoothing-serum">
            <StaticImage
              src="../../images/media/products/relift/products/wygladzajace-serum.png"
              loading="eager"
              // width={142}
              quality={95}
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="Smoothing eye serum"
            />
          </Link>
          <Link to="/en/products/lifting-serum">
            <StaticImage
              src="../../images/media/products/relift/products/liftingujace-serum.png"
              loading="eager"
              // width={142}
              quality={95}
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="liftingujące serum"
            />
          </Link>
          <Link to="/en/products/anti-aging-serum">
            <StaticImage
              src="../../images/media/products/relift/products/odmladzajace-serum.png"
              loading="eager"
              // width={142}
              quality={95}
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              alt="odmladzajace serum"
            />
          </Link>
        </ProductSidebar>
      </div>
      {/* <BubbleBg/> */}
    </ProductHero>
    <section id="opis-produktu">
      {/* TABS */}
      <Tabs color="magenta">
        {/* OPIS */}
        <div label="Description" className="tab-list-active">
          <ProductDescription color="magenta">
            <div className="descriptionRow">
              <div className="column">
                <div className="text--left">
                  <p>
                    <strong>
                      OXYGENETIC RELIFT VITAMIN A Ultra repair night-time face
                      serum
                    </strong>
                  </p>
                  <p>
                    This slow-release skincare serum is to enhance one's beauty
                    sleep by stimulating repair processes in the skin overnight.
                  </p>
                  <p>
                    This slow-release skincare serum is to enhance one's beauty
                    sleep by stimulating repair processes in the skin overnight.
                  </p>
                  <p>
                    Promotes collagen and elastin production and is merciless to
                    smooth even very deep wrinkles leaving the skin supple and
                    plump and with youthful bounce and elasticity restored to
                    the skin.
                  </p>
                  <p>
                    This lipid loving serum for deep-locked moisture while
                    sleeping is an anti-ageing expert to nourish, regenerate and
                    rejuvenate the skin. Proven to make skin glow overnight with
                    envious luminosity, smoothness, elasticity, freshness and
                    youthfulness restored.
                  </p>
                </div>
              </div>
              <div className="column">
                <DescriptionPackshot>
                  <StaticImage
                    src="../../images/media/products/relift/products/ultranaprawcze-serum_single.png"
                    loading="lazy"
                    width={534}
                    quality={95}
                    placeholder="none"
                    formats={["auto", "webp", "avif"]}
                    alt="ultranaprawcze serum"
                  />
                </DescriptionPackshot>
              </div>
              <div className="column">
                <div className="text--right">
                  <p>
                    <strong>How to use:</strong>
                  </p>

                  <p>
                    Cleanse the skin and apply every night before bed. For
                    exernal use only. Avoid contact with eyes.
                  </p>
                  <br />
                  <p>
                    <strong>Volume:</strong>
                  </p>
                  <p>50ml</p>
                  <Relift />
                </div>
              </div>
            </div>
          </ProductDescription>
        </div>

        {/* BAZA */}

        <div label="Base">
          <Ingredients>
            <Ingredient
              name="Retinol with adenosine"
              desc="is a golden standard for anti-ageing, the combination that works effectively to replenish skin cells by allowing new plump skin cells to form. Stimulates collagen production  to keep the skin looking youthful. Trusted for real results. Demonstrated a sinificant reduction in the appearance of wrinkles by strengthening fibroblast proliferation in the dermal layer and promoting collagen synthesis. You will surely notice smooth, firmed and refined complexion"
            />
            <Ingredient
              name="Blend of oxygentaing minerals"
              desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look."
            />
            <Ingredient
              name="Synbiotics"
              desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin."
            />
          </Ingredients>
        </div>

        {/* SKŁADNIKI AKTYWNE */}
        <div label="Active ingredients">
          <ActiveIngredients>
            <ActiveIngredient
              name="Alantoine"
              desc="intensly hydrates and soothes dry and irritated skin. Known to stimulate and promote poduction of baby plum cells for rejuvenating and revived effect."
            />
            <ActiveIngredient
              name="Shea butter"
              desc="enhances the natural skin renewal processes. Film-forming, preserves moisture and strenghtne skin barrier. Also, known to soften and nourishe."
            />
          </ActiveIngredients>
        </div>

        {/* SKUTECZNOŚĆ */}
        <div label="Effectivness">
          <Effectivness>
            <EffectivnessCard
              color="magenta"
              percentage="100"
              desc="badanych potwierdza, że przy regularnym stosowaniu skóra po przebudzeniu wygląda na odżywioną i zregenerowaną "
            />
            <EffectivnessCard
              color="magenta"
              percentage="93"
              desc="badanych potwierdza, że produkt wykazuje skuteczne działanie przeciwzmarszczkowe"
            />
            <EffectivnessCard
              color="magenta"
              percentage="100"
              desc="badanych potwierdza, że produkt zwiększa elastyczność i napięcie skóry"
            />
          </Effectivness>
        </div>
      </Tabs>
    </section>
  </Layout>
)

export default Product
